<template>
  <CRow>
    <CToaster v-if="toastr" :autohide="3000">
      <template v-for="(toast, i) in toastr">
        <CToast :show="showToast" :key="i" position="top-right">
          {{ toast }}
        </CToast>
      </template>
    </CToaster>

    <CCol>
      <div v-if="serverErrors.length">
        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible>
          <ul>
            <li v-for="err in serverErrors" :key="err">{{ err }}</li>
          </ul>
        </b-alert>
      </div>
      <form v-on:submit.prevent="updateStatus">
        <a
          href="javascript:void(0)"
          @click="getLocation"
          class="text-primary get-location"
          >Get Location From GPS</a
        >
        <div class="form-group">
          <label>Latitude</label>
          <input
            type="text"
            name="latitude"
            v-model="newMeter.latitude"
            class="form-control"
            placeholder="Ex: 23.756380"
          />
        </div>

        <div class="form-group">
          <label>Longitude</label>
          <input
            type="text"
            name="longitude"
            v-model="newMeter.longitude"
            class="form-control"
            placeholder="Ex: 90.4125"
          />
        </div>

        <div class="form-group">
          <label>Status</label>
          <select
            name="status"
            v-model="newMeter.status"
            class="form-control"
            required
          >
            <option value="">Select Status</option>
            <option value="METER_INSTALLED">METER INSTALLED</option>
            <option value="APP_INSTALLED">APP INSTALLED</option>
          </select>
        </div>

        <button type="submit" class="btn btn-primary">Submit</button>
      </form>
    </CCol>
  </CRow>
</template>

<script>
export default {
  name: "UpdateMeterStatus",
  props: {
    meter: {},
  },
  data: () => {
    return {
      showToast: false,
      toastr: [],
      toastr: [],
      serverErrors: [],
      newMeter: {
        status: "",
        latitude: "",
        longitude: "",
      },
      showDismissibleAlert: false,
    };
  },
  methods: {
    updateStatus() {
      try {
        this.axios
          .put(`/admin/meters/${this.meter.id}/status`, this.newMeter)
          .then((res) => {
            this.toastr = [];

            this.$toastr.s(
              "success",
              "Meter status successfully updated",
              "Meter Status Updated"
            );
            this.showToast = true;
            setTimeout(() => {
              this.$emit("closeUpdateMeterStatusModal", res.data.data);
            }, 2000);
          })
          .catch((err) => {
            this.showDismissibleAlert = true;
            this.serverErrors = [];
            if (err.response.data.message) {
              this.serverErrors.push(err.response.data.message);
            }
          });
      } catch (err) {
        console.log(err);
      }
    },
    getLocation() {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          this.newMeter.latitude = pos.coords.latitude;
          this.newMeter.longitude = pos.coords.longitude;
          this.toastr = [];
          this.showToast = true;
          this.$toastr.s(
            "success",
            "Latitude, Longitude successfully updated.",
            "Success!"
          );
        },
        (err) => {
          console.log(err);
          this.toastr = [];
          this.showToast = true;
          this.$toastr.e("error", err.message, "Geolocation Failed");
        }
      );
    },
  },
  watch: {
    meter: function (val) {
      //do something when the data changes.
      if (val) {
        this.newMeter = val;
      }
    },
    toastr(val) {
      this.toastr = val;
    },
  },
  mounted() {
    this.toastr;
    this.newMeter;
  },
};
</script>



<style scoped lang="scss">
.get-location {
  text-align: right;
  display: block;
  text-decoration: underline;
}
</style>
