<template>
  <div class="google-map" :id="mapName"></div>
</template>

<script>
import {Loader} from '@googlemaps/js-api-loader';

export default {
  name: "Map",
  props: {
    name: String,
    meter: Object
  },
  data: function () {
    return {
      mapName: this.name + "-map"
    };
  },
  mounted() {
    this.initializeMap()
  },
  methods: {
    initializeMap() {
      // Initialize and add the map
      let map;

      const loader = new Loader({
        apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
        version: "weekly",
        libraries: ["places"]
      });

      // The location of Uluru
      const position = {
        lat: parseFloat(this.meter.latitude),
        lng: parseFloat(this.meter.longitude)
      };

      loader.importLibrary('maps').then(({Map}) => {
        map = new Map(document.getElementById(this.mapName), {
          zoom: 15,
          center: position,
          mapId: "DEMO_MAP_ID",
        });
      }).catch((e) => {
        console.log(e, 'error')
      });

      loader.importLibrary('marker').then(({AdvancedMarkerElement}) => {
        const marker = new AdvancedMarkerElement({
          map: map,
          position: position,
          title: "Uluru",
        });
      }).catch((e) => {
        console.log(e, 'error')
      });
    }
  }
};
</script>
<style scoped lang="scss">
.google-map {
  width: 100%;
  height: 420px;
  margin: 15px auto 0;
  background: gray;
}
</style>
