<template>
  <div id="printArea">
    <CRow>
      <CCol>
        <h4 class="text-uppercase pb-4">Meter Detail</h4>
      </CCol>
    </CRow>

    <CRow>
      <CCol sm="4">
        <CCard class="p-5" v-if="meter.customer_account">
          <CRow>
            <CCol class="avatar-container">
              <img
                class="mb-2"
                name="cil-user"
                :src="getImgUrl('user.png')"
                alt="User Logo"
              />
            </CCol>

            <CCol class="mt-3">
              <h4>
                {{ meter.customer_account.first_name }}
              </h4>
              <p>
                Account No #<router-link
                  :to="`/consumers/${meter.customer_account.id}`"
              >{{ meter.customer_account.account_number }}</router-link
              >
              </p>
            </CCol>
          </CRow>

          <ul class="customer-detail-list">
            <li>
              <p>Category:</p>
              <p>{{ meter.customer_account.category }}</p>
            </li>
            <li>
              <p>Class:</p>
              <p>{{ meter.customer_account.class }}</p>
            </li>
            <li>
              <p>MI:</p>
              <p>{{ meter.customer_account.meter_inspector && meter.customer_account.meter_inspector.mi_code }}</p>
            </li>
            <li>
              <p>Mobile No:</p>
              <p>{{ meter.customer_account.mobile_number }}</p>
            </li>
            <li>
              <p>Email:</p>
              <p>{{ meter.customer_account.email }}</p>
            </li>
            <li>
              <p>Ward No:</p>
              <p>{{ meter.customer_account.ward_number }}</p>
            </li>
            <li>
              <p>Address 1:</p>
              <p>{{ meter.customer_account.address }}</p>
            </li>
            <li>
              <p>Address 2:</p>
              <p>{{ meter.customer_account.address2 }}</p>
            </li>
          </ul>
        </CCard>
        <CRow>
          <CCol class="p-3" sm="12">
            <Map
              v-if="meter.latitude && meter.longitude"
              :name="meter.meter_number"
              :meter="meter"
            />
          </CCol>
        </CRow>
      </CCol>
      <CCol sm="8">
        <CRow>
          <CCol sm="12">
            <CCard>
              <CRow>
                <CCol sm="6">
                  <h4 class="pb-4">Meter</h4>
                </CCol>
                <CCol sm="6" class="text-right d-print-none">
                  <CButton @click="print()" color="info" shape="pill"
                    >Print</CButton
                  >
                </CCol>
              </CRow>

              <div class="table-responsive">
                <table class="table table-borderless">
                  <thead>
                    <tr class="text-center">
                      <!-- <th class="font-weight-bold">Meter No</th> -->
                      <th class="font-weight-bold">Project</th>
                      <th class="font-weight-bold">Hardware Serial</th>
                      <th class="font-weight-bold">
                        Current <br />
                        Status
                      </th>
                      <th class="font-weight-bold">
                        Current <br />Consumption Unit
                      </th>
                      <th class="font-weight-bold">Last <br />Reading Time</th>
                      <th class="font-weight-bold d-print-none">Actions</th>
                    </tr>
                  </thead>
                  <tbody v-if="meter">
                    <tr class="text-center">
                       <td>{{ meter.project ? meter.project.name :'' }}</td>
                      <td>{{ meter.hardware_serial_no }}</td>
                      <td>
                        <span
                          class="badge"
                          :class="
                            meter.status === 'METER_ASSIGNED'
                              ? 'badge-warning'
                              : meter.status === 'METER_INSTALLED'
                              ? 'badge-primary'
                              : 'badge-success'
                          "
                          >{{ meter.status }}</span
                        >
                      </td>
                      <td>{{ meter.current_consumption_unit }}</td>
                      <td>
                        {{
                          meter.reading_date_time
                            ? dateFormat(meter.reading_date_time)
                            : "N/A"
                        }}
                      </td>
                      <td class="d-print-none">
                        <a
                          href="javascript:void(0)"
                          @click="showMeterStatusModal"
                          class="btn btn-sm btn-primary"
                          >Update Status</a
                        >
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="5">
                        <h5 class="text-center">Data Not Available.</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CCard>
          </CCol>

          <CCol sm="12">
            <CCard>
              <h4 class="pb-4">Status Logs</h4>
              <div class="table-responsive">
                <table class="table table-borderless">
                  <thead>
                    <tr class="text-center">
                      <th class="font-weight-bold">Event</th>
                      <th class="font-weight-bold">Description</th>
                      <th class="font-weight-bold">Created By</th>
                      <th class="font-weight-bold">Created At</th>
                    </tr>
                  </thead>
                  <tbody
                    v-if="
                      meter.meter_status_logs && meter.meter_status_logs.length
                    "
                  >
                    <tr
                      class="text-center"
                      v-for="log in meter.meter_status_logs"
                      :key="log.id"
                    >
                      <td>
                        <span
                          class="badge"
                          :class="
                            log.event_type === 'METER_ASSIGNED'
                              ? 'badge-warning'
                              : log.event_type === 'METER_INSTALLED'
                              ? 'badge-primary'
                              : 'badge-success'
                          "
                          >{{ log.event_type }}</span
                        >
                      </td>
                      <td>{{ log.event_description }}</td>
                      <td>{{ log.created_user }}</td>
                      <td>{{ dateFormat(log.created_at) }}</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="4">
                        <h5 class="text-center">Data Not Available.</h5>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </CCard>
          </CCol>
        </CRow>
      </CCol>
    </CRow>

    <CModal
      class="hide-footer"
      :closeOnBackdrop="false"
      title="Update Meter Status"
      color="primary"
      :show.sync="updateStatusModal"
    >
      <UpdateMeterStatus
        :meter="meter"
        @closeUpdateMeterStatusModal="closeUpdateMeterStatusModal"
      ></UpdateMeterStatus>
    </CModal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UpdateMeterStatus from ".././customers/partials/UpdateMeterStatus";
import Map from "./partial/Map";
export default {
  name: "MeterView",
  components: {
    UpdateMeterStatus,
    Map,
  },

  data: () => {
    return {
      meterId: Number,
      updateStatusModal: false,
      // currentPage: 1,
      // perPage: 10,
      // totalRows: 0,
      deletingState: false,
      loadingState: false,
      // role: {},
      serverErrors: [],
      showDismissibleAlert: false,
      breadcrumbs: [{text:"Home", path:"/dashboard", isActive: false}, {text:"Meters", path:"/meters", isActive: false}, {text:"View", path:"", isActive: true}]
      // isLoading: false,
    };
  },
  computed: {
    ...mapGetters("Meter", ["meter"]),
  },
  methods: {
    showMeterStatusModal() {
      this.updateStatusModal = true;
    },
    showMeterMapModal() {
      this.$refs["meter-map-modal"].show();
    },

    closeUpdateMeterStatusModal() {
      this.updateStatusModal = false;
    },
    dateFormat(date) {
      return moment(date).format("Do MMM, YYYY h:mm a");
    },
    getImgUrl(pic) {
      return require("../../assets/images/" + pic);
    },
    print() {
      // Get HTML to print from element
      const prtHtml = document.getElementById("printArea").innerHTML;
      // Get all stylesheets HTML
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }

      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            <title>Meter Detail - ${this.meter.hardware_serial_no}</title>
            ${stylesHtml}
          </head>
          <body>
            ${prtHtml}
          </body>
        </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.addEventListener('load', () => {
        setTimeout(()=> {
          WinPrint.print();
          WinPrint.close();
        },200)
      });
    },
  },
  watch: {
    $route(to, from) {
      this.meterId = this.$route.params.id;
      this.$store.dispatch("Meter/getmeterById", this.meterId);
    },
  },
  mounted() {
    this.meterId = this.$route.params.id;
    this.$store.dispatch("Meter/getmeterById", this.meterId);
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
};
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
.badge {
  padding: 5px 7px;
}
.update-status-btn-width {
  width: 100%;
}

.hide-footer >>> footer {
  display: none;
}

.custom-close-button >>> button {
  color: aliceblue;
}
.hide-footer >>> footer {
  display: none;
}
.card {
  border: none;
  padding: 30px 20px !important;
}
tbody:before {
  content: "@";
  display: block;
  /* line-height: 30px; */
  text-indent: -99999px;
}

.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
.badge {
  padding: 5px 7px;
}
.custom-width {
  width: 100%;
}
</style>

<style lang="scss" scoped>
.avatar-container {
  max-width: 100px;
}
.customer-detail-list {
  margin-top: 5px;
  list-style-type: none;
  padding: 0;
  li {
    width: 100%;
    float: left;
    p {
      float: left;
    }
    p {
      &:first-of-type {
        width: 35%;
        max-width: 100px;
        font-weight: 600;
      }
      &:last-of-type {
        width: 65%;
      }
    }
  }
}

div {
  .row {
    .card {
      p {
        color: #000;
      }
      .row {
        p {
          color: #5e76e7;
        }
      }
    }
  }
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
  .table {
    font-size: 14px;

    thead {
      tr {
        th {
          background: #f8f9fa;
          color: #000;
        }
      }
    }
    tbody {
      font-size: 13px;
      tr {
        color: #000;
        td {
          padding: 0.75rem 0 0.75rem 0.75rem;
          button {
            font-size: 13px;
          }
        }
        td:nth-child(1) {
          color: #5e76e7;
        }
        td:nth-child(2) {
          font-weight: 500 !important;
        }
      }
    }
  }
}
</style>
